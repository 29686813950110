.posts-title {
  padding-left: 8px;
  margin-top: 14px;
}
/* .post-excerpt {
  background: -webkit-linear-gradient(#000, #222, #eee);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

@media only screen and (max-width: 767px) {
  iframe {
    max-width: 240px !important;
    height: 160px;
  }

  h1,
  h2,
  h3 {
    font-size: 16px !important;
    font-weight: bold !important;
  }

  h1,
  h2,
  h3 {
    font-weight: bold !important;
  }

  h1 {
    font-size: 22px !important;
  }

  h2 {
    font-size: 19px !important;
  }

  h3 {
    font-size: 16px !important;
  }

  img {
    max-width: 240px !important;
  }

  .content {
    max-width: 300px;
  }
}

.noApp {
  display: none;
}

.has-text-align-center {
  text-align: center;
}

.has-large-font-size {
  font-size: large;
}

.has-huge-font-size {
  font-size: x-large;
}

.blog_feature .title {
  color: #222 !important;
  font-size: 16px !important;
  margin-bottom: 12px !important;
}
