@import "node_modules/bulma/sass/utilities/initial-variables.sass"

$pink: #ffb3b3
$pink-invert: #fff

$sini-blue: #b9e5fb
$sini-blue-invert: #fff

$dimensions: 14 16 20 24 28 32 48 64 96 128 160 256

$divider-font-size: $size-7

$input-placeholder-color: rgba(0, 0, 0, 0.6)

//$primary: $sini-blue
//$primary-invert: $sini-blue-invert
.site-logo-container
  position: relative
  text-align: center


.site-logo-paw
  margin-right: 4px


.site-logo-hostname-text
  position: absolute
  top: -7px
  left: 5px
  color: blue
  font-weight: bold


@import "bulma"
// Import Extensions
@import "node_modules/bulma-accordion/dist/css/bulma-accordion.sass"
@import "node_modules/bulma-pricingtable/dist/css/bulma-pricingtable.sass"
@import "node_modules/bulma-timeline/dist/css/bulma-timeline.sass"
@import "node_modules/bulma-divider/dist/css/bulma-divider.sass"
@import "node_modules/@creativebulma/bulma-badge/src/sass/index.sass"
@import "node_modules/bulma-slider/src/sass/index.sass"
@import "node_modules/bulma-extensions/bulma-switch/src/sass/index.sass"
