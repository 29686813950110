.tileWrapper {
  border-radius: 4px !important;
}

.tileBodyImage {
  cursor: pointer;
  padding: 2rem;
  position: relative;
}

.tileFooterItem {
  cursor: pointer;
}

.tileHeader {
  height: 2.75rem;
}

.tileHeaderTitle {
  font-size: 0.7rem;
  padding: 0.5rem !important;
  display: flex;
  justify-content: center;
  text-align: center;
}

.tileHeaderButton {
  cursor: pointer;
}

.tileHeaderButton {
  padding: 0;
}

.tileHeaderIcon {
  padding: 0 !important;
  margin-right: 0.2rem;
}

.disabled {
  opacity: 0.3;
  filter: alpha(opacity=40);
}

@media only screen and (max-width: 600px) {
  .tileBodyImage {
    padding: 1rem;
  }
}
