.marker,
.markerDetails {
  background-color: #febe10;
  border-radius: 3px;
}

.marker {
  padding: 2px 7px;
}

.markerDetails {
  padding: "6px 12px";
  width: 30px;
  height: 28px;
  font-size: 16px;
  text-align: center;
}
